<template>
  <div class="button-group d-flex" :class="`button-group--${curIndex}`">
    <div :style="`transform: translateX(${translateX})`" class="button-group__bg-block"></div>
    <div
      v-for="(button, index) in buttonList"
      @click="choose(index)"
      :key="button"
      class="button-group__btn"
      :class="{ 'button-group__btn--active': index == curIndex }"
    >
      {{ button }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    buttonList: ["寫入", "續傳", "讀取"],
    curIndex: 0,
  }),
  computed: {
    curButton() {
      return this.buttonList[this.curIndex];
    },
    translateX() {
      return `${100 * this.curIndex}%`;
    },
  },
  watch: {
    value() {
      this.curIndex = this.value;
    },
  },
  methods: {
    choose(index) {
      this.curIndex = index;
      this.$emit("input", index);
    },
  },
};
</script>

<style lang="sass">
.button-group
    max-width: 300px
    margin: 0 auto
    position: relative
    border-radius: 25px
    background: #3D3D3D
    color: #FFFFFF
    cursor: pointer
    &__bg-block
      width: calc(100% / 3)
      background: #DFDFDF
      border-radius: 25px
      position: absolute
      left: 0
      top: 0
      height: 100%
      transition: transform 0.5s ease
      transform: translateX(0)
    &__btn
        padding: 10px 20px
        width: calc(100% / 3)
        text-align: center
        position: relative
        z-index: 1
        color: white
        transition: color 0.6s ease
        &--active
            color: #3D3D3D
</style>